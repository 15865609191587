const AI_SET_PROMPT = 'ai/GET_PROMPT' as const;

export interface AiPromptType {
  _id: string;
  provider: string;
  memberId?: string;
  title: string;
  blockType: string;
  content?: string;
  isDefault: boolean;
  sort: number;
}

export interface AiProps {
  aiPromptList: AiPromptType[];
}

const initialState: AiProps = {
  aiPromptList: [],
};

export const setAiPrompt = (data: AiPromptType[]) => ({
  type: AI_SET_PROMPT,
  payload: data,
});

type AiActionType = typeof setAiPrompt;

function Ai(state: AiProps = initialState, action: ReturnType<AiActionType>) {
  switch (action.type) {
    case AI_SET_PROMPT:
      return {
        ...state,
        aiPromptList: action.payload,
      };

    default: {
      return state;
    }
  }
}

export default Ai;
