/**
 * 루트 리듀서 컴바인
 */

import { combineReducers } from 'redux';
import member from './Member';
import canvasUI from './CanvasUI';
import scenarios from './Scenarios';
import bots from './Bots';
import template from './Template';
import blockEditor from './BlockEditor';
import moduleHistory from './ModuleHistory';
import settingBot from './BotSetting';
import parameter from './Parameter';
import tutorial from './Tutorial';
import buttonDesigner from './ButtonDesigner';
import library from './Library';
import deployHistory from './DeployHistory';
import botSession from './BotSession';
import participation from './Participation';
import message from './Message';
import workHistory from './WorkHistory';
import authList from './AuthList';
import ratingList from './RatingList';
import statistics from './Statistics';
import statisticsConfig from './StatisticsConfig';
import tags from './Tags';
import syncList from './SyncList';
import statList from './StatList';
import counselorList from './CounselorList';
import analyzation from './Analyzation';
import commonItem from './CommonItem';
import transmission from './Transmission';
import RegExpItems from './RegExpItems';
import bizMsg from './BizMsg';
import surveyFormCards from './SurveyFormCards';
import surveyAnswer from './SurveyAnswer';
import surveySession from './SurveySession';
import nlp from './Nlp';
import ar from './Ar';
import botCounsultTime from './BotsCounsultTime';
import botSetup from './BotSetup';
import editorError from './EditorError';
import modalController from './ModalController';
import statData from './StatData';
import ai from './Ai';

const rootReducer = combineReducers({
  member,
  canvasUI,
  scenarios,
  bots,
  template,
  blockEditor,
  moduleHistory,
  tutorial,
  buttonDesigner,
  // gi setting reducer 추가
  settingBot,
  parameter,
  library,
  //ms setting reducer
  deployHistory,
  botSession,
  message,
  workHistory,
  authList,
  ratingList,
  statistics,
  statisticsConfig,
  tags,
  syncList,
  statList,
  counselorList,
  analyzation,
  participation,
  transmission,
  bizMsg,
  commonItem,
  RegExpItems,
  surveyFormCards,
  surveyAnswer,
  surveySession,
  nlp,
  ar,
  botCounsultTime,
  botSetup,
  editorError,
  modalController,
  statData,
  ai,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
