export const DefaultBlockLimit = 100;
export const SlideLimit = {
  carousel: 10,
  list: 5,
};

export enum BlockActionNames {
  TEXT = 'DEFAULT_TEXT',
  IMAGE = 'DEFAULT_IMAGE',
  CARD = 'DEFAULT_IMAGE_CARD',
  CARD_SQUARE = 'DEFAULT_IMAGE_CARD_SQUARE',
  COMMERCE = 'DEFAULT_IMAGE_COMMERCE',
  COMMERCE_SQUARE = 'DEFAULT_IMAGE_COMMERCE_SQUARE',
  LIST = 'DEFAULT_LIST',
  CA_TEXT = 'CAROUSEL_TEXT',
  CA_CARD = 'CAROUSEL_IMAGE_CARD',
  CA_CARD_SQUARE = 'CAROUSEL_IMAGE_CARD_SQUARE',
  CA_COMMERCE = 'CAROUSEL_IMAGE_COMMERCE',
  CA_COMMERCE_SQUARE = 'CAROUSEL_IMAGE_COMMERCE_SQUARE',
  CA_LIST = 'CAROUSEL_LIST',
  BTN_LINK = 'BUTTON_LINK',
  BTN_LIST = 'BUTTON_LIST',
  AUTH_API = 'DEFAULT_TEXT_AUTH_API',
  SMS_AUTH_API = 'DEFAULT_SMS_AUTH_API',
  ADD_CHANNEL = 'DEFAULT_ADD_CHANNEL',
  POST_APPLY = 'DEFAULT_POST_APPLY',
  ROOM_INFO_SEND = 'DEFAULT_ROOM_INFO_SEND',
  ROOM_INFO_GET = 'DEFAULT_ROOM_INFO_GET',
  API = 'DEFAULT_TEXT_API',
  CONDITION = 'BUTTON_CONDITION',
  MULTI_CONDITION = 'BUTTON_MULTI_CONDITION',
  WORK_CONDITION = 'BUTTON_WORK_CONDITION',
  AR_TASK_CONDITION = 'BUTTON_TASK_CONDITION',
  AR_MESSAGE = 'DEFAULT_CALL_SEND_MSG',
  AR_INPUT = 'DEFAULT_CALL_TEXT_UR',
  AR_INPUT_VOICE = 'DEFAULT_CALL_TEXT_UR_VOICE',
  AR_BUTTON = 'DEFAULT_CALL_TEXT',
  AR_OPTION = 'DEFAULT_CALL_OPTION',
  AR_CONNECT = 'DEFAULT_CALL_CONNECT',
  AR_RESERVATION = 'DEFAULT_CALL_RESERVATION',
  VARIABLES_INIT = 'BUTTON_VARIABLES_INIT',
  VARIABLES_MATCH = 'BUTTON_VARIABLES_MATCH',
  AR_END = 'DEFAULT_CALL_END',
  // 고객사 커스텀 블록
  DEFAULT_INTEGRATION_API = 'DEFAULT_INTEGRATION_API',
  KAKAO_PROFILE_AUTH = 'DEFAULT_KAKAO_PROFILE_AUTH',
  AI_INTENT_ANALYSIS = 'DEFAULT_AI_INTENT_ANALYSIS',
}

export enum BlockNames {
  DEFAULT_TEXT = '텍스트 블럭',
  DEFAULT_IMAGE = '이미지 블럭',
  DEFAULT_IMAGE_CARD = '와이드 카드 블럭',
  DEFAULT_IMAGE_CARD_SQUARE = '정사각 카드 블럭',
  DEFAULT_IMAGE_COMMERCE = '와이드 커머스 블럭',
  DEFAULT_IMAGE_COMMERCE_SQUARE = '정사각 커머스 블럭',
  DEFAULT_LIST = '리스트 블럭',
  CAROUSEL_TEXT = '슬라이드 텍스트',
  CAROUSEL_IMAGE_CARD = '슬라이드 와이드 카드',
  CAROUSEL_IMAGE_CARD_SQUARE = '슬라이드 정사각 카드',
  CAROUSEL_IMAGE_COMMERCE = '슬라이드 와이드 커머스',
  CAROUSEL_IMAGE_COMMERCE_SQUARE = '슬라이드 정사각 커머스',
  CAROUSEL_LIST = '슬라이드 리스트',
  BUTTON_LINK = '바로 연결',
  BUTTON_LIST = '리스트 메뉴',

  DEFAULT_TEXT_API = 'API 블럭',
  BUTTON_CONDITION = '조건 블럭',
  BUTTON_MULTI_CONDITION = '다중 조건 블럭',
  BUTTON_WORK_CONDITION = '주간일정 관리 블럭',
  BUTTON_TASK_CONDITION = '운영시간 조건 블럭',
  DEFAULT_CALL_SEND_MSG = '메시지 전송 블럭',
  DEFAULT_CALL_TEXT_UR_VOICE = '음성인식 블럭',
  DEFAULT_CALL_TEXT_UR = '고객입력 블럭',
  DEFAULT_CALL_TEXT = '안내 블럭',
  DEFAULT_CALL_OPTION = '옵션 블럭',
  DEFAULT_CALL_CONNECT = '상담배정 블럭',
  DEFAULT_CALL_RESERVATION = '콜백예약 블럭',
  BUTTON_VARIABLES_INIT = '변수 초기화 블럭',
  BUTTON_VARIABLES_MATCH = '변수 설정 블럭',
  DEFAULT_CALL_END = '플로우 종료 블럭',
  DEFAULT_POST_APPLY = '주소 등록 블럭',
  DEFAULT_TEXT_AUTH_API = 'AUTH 블럭',
  DEFAULT_SMS_AUTH_API = 'SMS AUTH 블럭',
  DEFAULT_ROOM_INFO_SEND = '사용자 정보 설정 블럭',
  DEFAULT_ROOM_INFO_GET = '사용자 정보 호출 블럭',
  DEFAULT_ADD_CHANNEL = '채널 추가',

  /** 외부 연동 API 블럭 */
  DEFAULT_INTEGRATION_API = '외부 연동 API 블럭',
  DEFAULT_KAKAO_PROFILE_AUTH = '카카오 인증블럭 호출',
  DEFAULT_AI_INTENT_ANALYSIS = 'AI 의도 분석 블럭',
}

export interface AddNextBlockAR {
  item: any;
  count: number;
}
